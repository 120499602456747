.mt-1 {
  margin-top: 10px;
}
.mt-2 {
  margin-top: 20px;
}
.mt-3 {
  margin-top: 30px;
}
.mt-4 {
  margin-top: 40px;
}
.mb-1 {
  margin-bottom: 10px;
}
.mb-2 {
  margin-bottom: 20px;
}
.mb-3 {
  margin-bottom: 30px;
}
.mb-4 {
  margin-bottom: 40px;
}
.text-center {
  text-align: center;
}
.icon-red {
  /* color: rgb(25, 194, 250); */
  color: red;
}
.custom-modal .modal-ok-btn {
  color: #ffffff !important;
  background-color: rgb(12, 184, 12);
  border: 1px solid rgb(12, 184, 12);
}
.custom-modal .ant-modal-footer .modal-ok-btn:hover {
  background-color: rgb(2, 153, 2) !important;
  border: 1px solid rgb(12, 184, 12);

}
.custom-modal .ant-modal-footer .modal-cancel-btn {
  border: 1px solid red;
  color: red;
}
.custom-modal .ant-modal-footer .modal-cancel-btn:hover {
  background-color: red;
  color: #ffffff;
}
