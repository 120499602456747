.sider {
  background-color: #A20427 !important;
  font-family: 'Times New Roman', Times, serif;
}
.sider-menu {
  background-color: #A20427 !important;
  color: #ffffff !important ;
  margin-top: 30px !important;
}
.sider-menu-item {
  margin-bottom: 15px !important;

}
.sider-menu-item:hover {
  background-color: #fff;
  color: #A20427 !important;
  transition: 0.5s ease-in-out !important;
}

.submenu-item {
  color: #ffffff;
}
.submenu-item:hover {
  color: #A20427 !important;
  background-color: #ffffff !important;
}

.active-menu {
  background-color: #fff;
  color: #A20427 !important;
  margin-bottom: 15px !important;
}

.ant-menu-submenu-arrow {
  color: #ffffff !important;
}

.ant-layout-sider-trigger {
  background-color: #ffffff !important ;
  color: #A20427 !important;
  border-right: 1px solid !important;
}

/* Submenu */
.ant-menu-submenu-title:hover {
  color: #ffffff !important;
  background-color: transparent !important;
}
.ant-menu-title-content {
  margin: 0px !important;
  padding: 0;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.bg-transparent {
  background-color: #A20427 !important;
}
.border-bottom-white {
  border-bottom: 1px solid #6c817d !important;
}
.sider-menu-item-title {
  padding-left: 10px;
}
