.content{
    padding: 20px 0px;
}
.input{
    font-size: 15px;
    border: 1px solid #A20427;
    color:#000000 ;
    border-radius: 8px;
    padding: 10px 20px;
    width: 100%;
}
select{
        font-size: 15px;
        border: 1px solid #A20427;
        color:#000000 ;
        border-radius: 8px;
        padding: 10px 20px;
        width: 100%;
    
}
.mb20{
    margin-bottom: 20px;
}

.imgBox{
    width: 120px;
    height: 120px;
    position: relative;
    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 20px;
    border: 1px solid #A20427;
}
.imgBox img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
 .avatar{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: #dddddd14;
}
.avatar svg{
    color:#A20427;
}