.main {
  background: #fff !important;
  min-height: 700px;
  width: 100%;
  height: 100%;
}

@media (max-width: 600px) {
  .main {
    min-height: 90vh;
  }
}

@media (min-width:1200px) {
  .main{
    min-height: 100vh;
  }
}
