body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  padding: 0px 5% !important;
  max-width: 100% !important;
  height: auto;
}
.pt-5{
  padding-top: 5%;
}
.heading {
  text-align: center;
  padding: 5% 0% 3% 0px;
  font-size: 40px !important;
  color: #A20427 !important;
  font-family: 'Times New Roman', Times, serif;  
}

.custom-btn {
  background-color: #A20427 !important;
  color: #fff !important;
  border: 0px !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  padding: 0px 25px !important;
  height: 40px !important;
  font-family: 'Times New Roman', Times, serif;
}
.custom-btn:hover {
  background-color: #af0f32 !important;
  transition: 0.5 all ease !important;
}

.green-btn {
  color: #ffffff;
  cursor: pointer;
  background-color: rgb(12, 184, 12);
  border: none;
  border-radius: 8px;
  height: 42px;
  min-width: 40px;
  font-family: 'Times New Roman', Times, serif;
}
.red-btn {
  color: #ffffff;
  cursor: pointer;
  background-color: red;
  border: none;
  border-radius: 8px;
  height: 42px;
  min-width: 40px;
  font-family: 'Times New Roman', Times, serif;
}
.done {
  color: white;
  background-color: rgb(12, 184, 12);
  border-radius: 3px;
  border: none;
  padding: 2px 10px;
  font-family: 'Times New Roman', Times, serif;
}
.pending {
  color: white;
  background-color: red;
  border-radius: 3px;
  border: none;
  padding: 2px 10px;
  font-family: 'Times New Roman', Times, serif;
}
.in-progress {
  color: white;
  background-color: rgb(248, 172, 29);
  border-radius: 3px;
  border: none;
  padding: 2px 10px;
  font-family: 'Times New Roman', Times, serif;
}

.w-100{
  width:100%;
}
.float-r{
  float: right;
}
.mr-1{
  margin-right: 10px;
}
.mr-2{
  margin-right: 20px;
}
.mr-3{
  margin-right: 30px;
}
.mr-4{
  margin-right: 40px;
}
.h-100{
  height: 100%;
}
.green-bg{
  background-color: rgb(81, 245, 81);
}
.orange-bg{
  background-color: rgb(251, 151, 70);
}
select, input{
  outline: none;
}
button{
  cursor: pointer;
}
