.login-background {
  background-image: url("../../assets/bg-img.png");
  background-size: cover;
  height: 100%;
  width: 100%;
  min-height: 700px;
}

.layer {
  background-color: rgba(0, 0, 0, 0.37);
  width: 100%;
  height: 100%;
  min-height: inherit;
  padding: 10% 0px ;


}


@media (max-width: 600px) {
  .login-background  {
    min-height: 90vh;
  }
  .centerForm {
    display: flex;
    align-items: center;
    min-height: 90vh;

  }
  .layer{
    padding: 0px !important;
  }
  .centerForm .ant-row{
    width: 100%;
    height: 100%;
  }
}

@media screen and  (min-width:1100px) {
  .login-background {
    min-height: 90vh;
  }
  
.layer {
  padding: 10% 0px !important;

}
}
@media screen and  (min-width:1200px) {
  .login-background {
    min-height: 90vh;
  }
  
.layer {
  padding: 10% 0px !important;

}

}
