.login-card {
  background-color: white;
  width: 100%;
  border-radius: 10px;
  border: 1px solid lightgrey;
  align-items: center;
  justify-content: center;
  padding: 10%;
}
.login-heading {
  text-align: center;
  margin-bottom: 10%;
  color: #A20427 !important;
  font-size: 40px ;
  font-family: 'Times New Roman', Times, serif;
}
.input-item {
  margin-bottom: 30px !important;
  font-family: 'Times New Roman', Times, serif;
}
.ant-input {
  font-size: 15px !important;
  font-family: 'Times New Roman', Times, serif;
}
.input {
  padding: 8px 10px !important;
  border-radius: 5px !important;
  font-size: 15px !important;
  margin-top: 2px !important;
}
.login-button {
  width: 100%;
  background-color: #A20427;
  color: #fff;
  border: 0px;
  border-radius: 5px;
  margin-top: 5%;
  font-size: 18px;
  padding: 9px 0px;
  font-family: 'Times New Roman', Times, serif;
}

@media (max-width: 600px) {
  .login-card {
    padding: 10% 7%;
  }.login-heading {
    font-size: 30px !important;
  }
  
}
