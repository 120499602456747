.header {
  padding: 0px 40px;
  padding-top: 15px;
  height: 65px;
  background-color: #A20427 !important;
}
.title {
  color: #fff !important;
  font-size: 27px !important;
  font-family: 'Times New Roman', Times, serif;
}
.right-section {
  float: right;
}
.logout-btn {
  border-radius: 5px;
  outline: none;
  border: none;
  padding: 7px 10px;
  color: #A20427 !important;
  background-color: #fff !important;
  border: 1px solid #fff !important;
  cursor: pointer;
  font-family: 'Times New Roman', Times, serif;
}

.logout-btn:hover, .logout-btn:active {
  background-color: #c20931 !important;
  transition: 0.5s all ease;
  border: 1px solid #fff !important;
  color: #fff !important;
}

@media (max-width:768px){
    .header{
        padding: 0px 20px;
        padding-top: 15px;
    }
    .title{
        font-size: 24px !important;
    }
}