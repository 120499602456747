/* .table{
    font-family: 'Times New Roman', Times, serif;   
} */

 table thead tr th{
    font-family: 'Times New Roman', Times, serif !important; 
    font-weight: 800 !important;

}
 table tbody tr td{
    font-family: 'Times New Roman', Times, serif !important; 
    font-weight: 400 !important;

}